// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fotos-js": () => import("./../../../src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-hethuis-js": () => import("./../../../src/pages/hethuis.js" /* webpackChunkName: "component---src-pages-hethuis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seizoenen-js": () => import("./../../../src/pages/seizoenen.js" /* webpackChunkName: "component---src-pages-seizoenen-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */)
}

